<template>
  <div class="GalleryMenuContent">
    <div>
      <LargeButton icon="filter-remove" :label="$locale['remove_filter']" @click="execute('goTo', '/?feed=gallery')" />
    </div>

    <div v-if="$isAdmin">
      <Spacer num="1" :style="sepStyle" />
      <LargeButton icon="cloud-arrow-up" :label="$locale['upload_images']" @click="execute('uploadImages')" />
      <LargeButton icon="plus" :label="$locale['create_category']" @click="execute('createCategory')" />
      <LargeButton icon="pencil" :label="$locale['edit_category']" @click="execute('editCategory')" v-if="$route.query.category" />
      <LargeButton icon="trash" :label="$locale['delete_category']" @click="execute('deleteCategory')" v-if="$route.query.category" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["modal"],
  data: function() {
    return {
      sepStyle: {
        "background-color": "var(--body-background)",
        "border-top": "solid 1px var(--dborder)",
        "border-bottom": "solid 1px var(--dborder)",
      },
    };
  },
  methods: {
    execute: function(action, data) {
      this.modal.close(() => {
        this[action](data);
      });
    },
    goTo: function(url) {
      this.$router.push(url).catch(() => {});
    },
    uploadImages: function() {
      if (!this.$route.query.category) {
        return this.alert(this.$locale["select_a_category"]);
      }

      this.view({
        title: this.$locale["upload_images"],
        component: () => import("./GalleryUpload.vue"),
        class: "menu bottom uploadImages",
        animation: "bottomIn",
      });
    },
    createCategory: function() {
      this.view({
        title: this.$locale["create_category"],
        component: () => import("./GalleryCategory.vue"),
        class: "menu bottom",
        closeOutside: true,
        animation: "bottomIn",
      });
    },
    deleteCategory: function() {
      this.view({
        title: this.$locale["delete_category"],
        component: () => import("./GalleryCategory.vue"),
        class: "menu bottom",
        closeOutside: true,
        animation: "bottomIn",
        isdelete: true,
      });
    },
    editCategory: function() {
      this.view({
        title: this.$locale["edit_category"],
        component: () => import("./GalleryCategory.vue"),
        class: "menu bottom",
        closeOutside: false,
        animation: "bottomIn",
        edit: true,
      });
    },
  },
};
</script>
